@layer tailwind-base, primeng, tailwind-utilities;
@import 'assets/scss/themes/mytheme/theme.scss';

@layer tailwind-base {
	@tailwind base;
}

@layer tailwind-utilities {
	@tailwind components;
	@tailwind utilities;
}



.xng-breadcrumb-separator {
	padding: 0 4px;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.xng-breadcrumb-root {
	color: #242A3C !important;
	opacity: 0.5;
}

.xng-breadcrumb-item {
	font-size: 0.875rem;
	line-height: 1.25rem;
}